import * as React from 'react';
import { Outlet } from 'react-router-dom';

import Header from '@app/components/UI/Header/Header';
import Footer from '@app/components/UI/Footer/Footer';

import '@app/scss/pages/home.scss';

interface Props {
	component?: any;
	withoutHeader?: boolean;
	withoutFooter?: boolean;
}

export const MainRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
	<div className="home-page">
		{!rest.withoutHeader && <Header />}
		{Component ? <Component /> : <Outlet />}
		{!rest.withoutFooter && <Footer />}
	</div>
);
