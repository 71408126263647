import * as React from 'react';

import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
import { ErrorBoundaryWithLocation } from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';

import Gtm from '@app/components/UI/Gtm/Gtm';
import CompanySettingsProvider from '@app/components/UI/CompanySettingsProvider';
import RouteChangeTracker from '@app/components/Routes/RouteChangeTracker';

import '@app/scss/style.scss';

const Layout: React.FC = ({ children }) => <div className="app-container">
	<ErrorBoundaryWithLocation>
		<CompanySettingsProvider>
			<RouteChangeTracker>
				<RequestProvider>
					<div className="content">{children}</div>
					<Gtm />
				</RequestProvider>
			</RouteChangeTracker>
		</CompanySettingsProvider>
	</ErrorBoundaryWithLocation>
</div>;

export default Layout;
