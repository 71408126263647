import React from 'react';
import { Collapse } from 'react-collapse';

import { Faq } from '@commonTuna/react/objects/Faq';

interface Props {
	faqs: Array<Faq>;
}

const QuestionNode: React.FC<{ faq: Faq }> = (props) => {
	const { answerEn, questionEn } = props.faq;
	const [isOpen, setOpen] = React.useState(false);

	return (
		<div className="question">
			<div className={`collapse-trigger ${isOpen ? 'open' : ''}`} onClick={() => setOpen((prev) => !prev)}>
				<h3>
					{questionEn}
				</h3>
				{answerEn && <i className={`fa fa-angle-down ${isOpen ? 'open' : ''}`} />}
			</div>
			{answerEn && <Collapse isOpened={isOpen} checkTimeout={500}>
				<div className="answer" style={{ opacity: isOpen ? 1 : 0.1 }}>
					{answerEn}
				</div>
			</Collapse>}
		</div>
	);
};

const emptyFaq: Array<Faq> = [
	{
		id: -1,
		questionEn: 'no FAQ yet',
		answerEn: '',
	} as Faq,
];

const FAQ: React.FC<Props> = ({ faqs }) => {
	return (
		<ul className="faq">
			{(faqs.length ? faqs : emptyFaq).map((item) => <li key={item.id}><QuestionNode faq={item} /></li>)}
		</ul>
	);
};

export default FAQ;
