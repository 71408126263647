import * as React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay, params } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';

import Layout from '@app/components/Layout';
import { MainRoute } from '@app/components/Routes/MainRoute';

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HomePage" */ '@app/components/Pages/Home/Home')), params);
const Blog = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BlogPage" */ '@app/components/Pages/Blog/Blog')), params);
const Article = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ArticlePage" */ '@app/components/Pages/Article/Article')), params);

export const baseRoutes = (<>
	<Route path="/" element={<MainRoute />}>
		<Route path="/" element={<Home />} />
		<Route path="/blog" element={<Blog />} />
		<Route path="/blog/:path" element={<Article />} />
	</Route>
	<Route path="*" element={<NotFoundRoute openRoute={MainRoute} />} />
</>);

export const clientRoutes = (<Route
	element={<Layout>
		<Outlet />
	</Layout>}
>
	{baseRoutes}
</Route>);

export const routes = (<Layout>
	<Routes>
		{baseRoutes}
	</Routes>
</Layout>);
