import 'raf/polyfill';

import 'core-js/features/array/from';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/weak-map';
import 'core-js/features/promise';

import { bootClient, renderApp } from '@common/react/loadable/boot-client';
import { updateReducers } from '@common/react/configureStore';
import { BaseUser } from '@common/typescript/objects/BaseUser';

import { ApplicationState, reducers } from '@app/store';
import { clientRoutes } from '@app/routes';

bootClient<BaseUser, ApplicationState>(clientRoutes, reducers);

// Allow Hot Module Replacement
if (module.hot) {
	module.hot.accept('@app/routes', () => {
		renderApp((require('@app/routes') as any).routes);
	});
}

// Enable Webpack hot module replacement for reducers
if (module.hot) {
	module.hot.accept('@app/store', () => {
		const nextRootReducer = require('@app/store');
		updateReducers((nextRootReducer as any).reducers);
	});
}
